import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CustomSidebar, PageTitle, PageMiddle, PageContainer, CustomInput, CustomButton } from '../components'
import { groupsService, userService, userActions } from '../services'
import _ from 'lodash';
import { SimpleSelect } from 'dropdown-select';
import 'dropdown-select/dist/css/dropdown-select.css';
import copy from 'copy-to-clipboard';

export default () => {

  const [state, setState] = useState({
    groups: [],
    group: '',
    users: [],
    updates: {}
  })

  useEffect(() => {
    (async () => {
      await getGroups()
    })()
  }, [])

  useEffect(() => {
    (async () => {
      !!state.group && await getUsers()
    })()
  }, [state.group])

  const getGroups = async () => {
    const groups = await groupsService.listAll()
    setState({ ...state, groups })
  }

  const getUsers = async () => {
    const users = await userService.findByGroup(state.group)
    setState({ ...state, users, updates: {} })
  }

  const addGift = async (user_id, value) => {
    return await userActions.create(user_id, value)
  }

  const deposit = async (e) => {
    e.preventDefault()
    let itemsToUpdate = []

    Object.keys(state.updates).forEach((user_id) => {
      if (state.updates[user_id].money !== 0 && !isNaN(state.updates[user_id].money)) {
        itemsToUpdate.push(addGift(user_id, state.updates[user_id].money))
      }
    })

    if (!itemsToUpdate.length || itemsToUpdate.length > 1) {
      return window.swal.fire({
        icon: 'error',
        title: 'Error, please update just ONE student!'
      })
    }
    try {
      const [{ id }] = await Promise.all(itemsToUpdate)
      const link = "https://www.yesidoenglishcourse.com.br/money-gift/confirm/" + id
      window.swal.fire({
        timer: 30000,
        title: 'Deseja copiar o link?',
        text: link,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, copiar!'
      }).then((result) => {
        if (result.isConfirmed) {
          copy(link)
          window.swal.fire(
            'Copiado!',
            'success'
          )
        }
      })
      await getUsers()
    } catch (e) {
      window.swal.fire({
        icon: 'error',
        title: 'Error'
      })
    }
  }

  return (
    <PageContainer style={{ overflowX: 'hidden' }}>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          GIVE A GIFT TO ONE STUDENT
        </PageTitle>
        <FormContainer onSubmit={deposit}>
          <FormInputPadding style={{
            // maxHeight: 'calc(100vh - 95px)',
            // overflowY: 'auto',
            // minHeight: 300,
          }}>
            <PageTitle style={{ backgroundColor: 'white' }}>
              SELECT THE GROUP
            </PageTitle>
            <Separator />
            <SimpleSelect options={state.groups} labelKey="name" valueKey="id" value={state.groups.find(i => i.id == state.group) || ''} onChange={({ id }) => setState({ ...state, group: id })} />
            <Separator />
            {state.users.map(user => {
              return (
                <div style={{ display: 'flex', marginBottom: 15, alignItems: "center" }}>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>{`${user.first_name} ${user.last_name}`}</div>
                  <div style={{}}>
                    <Input onChange={e => {
                      if (isNaN(e.target.value)) return;
                      const new_state = { ...state }
                      new_state.updates[user.id] = new_state.updates[user.id] || { money: 0, balance: user.saldo }
                      new_state.updates[user.id] = {
                        ...new_state.updates[user.id],
                        money: parseInt(e.target.value)
                      }
                      setState({ ...state, ...new_state })
                    }} placeHolder={"Valor..."} type={'number'} value={state.updates[user.id]?.money || ''} />
                  </div>
                  <div style={{ width: 50, marginLeft: 10 }}>
                    {typeof (user.saldo) === 'number' ? user.saldo : 0}
                  </div>
                </div>
              )
            })}
            <Separator />
            <CustomButton name='DEPOSIT' />
          </FormInputPadding>
        </FormContainer>
      </PageMiddle>
    </PageContainer>
  )
}

const FormContainer = styled.form`
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
`

const Balance = styled(PageTitle)`
  color: green !important;
`

const FormInputPadding = styled.div`
  padding: 30px 20px;
`

const Input = styled(CustomInput)`
  border: 1px solid black;
  border-radius: 4px;
  color: black;
`

const Separator = styled.div`
margin-top: 15px;
`