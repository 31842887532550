import React from 'react'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      adicionarSaldo: true,
      title: 'Unit 1 - Good Memories',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdHdyNfJkar-b0s5zaQR9eu2D5ZD8npPB45IHXIYlvu0j3yQQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 2 - Life in the city',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfi9SSG8KkdOvAuUf1kLs5BwRh5iHJXd5LbfuymN62i24HnuQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 3 - Making Changes',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdWj7UoM7XrqMlYVy0yZ_XA9KnYwUpb83uZ1js8ZwabUg_i1Q/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 4 - Have you tried it?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScZOAphzvmzVw3YWm6pn7EvUysZY2DF0LEGfBoe63yLQHcY6A/viewfo'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 5 - Hit the road!',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSddgINq6LF0-fjfLyO36nov3BOvKg2TKPUm63JHsFwhBNPXQA/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 6 - Sure, I'll do it!",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeW4eyDBTG3yKatJqEjJb2M9BcLvokVk-KWGXUPB6acKc7FEQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 7 - What do you use this for?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSc6L3XJw7ddF7tEOIUEeKTGnSTvdrYXdHhwlXHeUER49MFTuQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 8 - Time to Celebrate",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSf5WMWY6b7c0meoG2R0I9yrSxtnnQp8dCs1DDLhEBZCiFz9eg/viewform'
    },
  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          Student Book 3 - Freedom!
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard color={'blue'} {...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
