import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo_fundo_transparente.png'
import { CustomSidebar, PageTitle, PageMiddle, PageContainer, CustomInput, CustomButton } from '../components'
import { UserContext } from '../contexts'
import { userService } from '../services'
import price from '../utils/price'
import _ from 'lodash';

export default () => {

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    (async () => {
      await getUserSaldo()
    })()
  }, [])

  const getUserSaldo = async () => {
    const { saldo } = await userService.saldo()
    setBalance(saldo)
  }
  const { user } = useContext(UserContext);

  const [value, setValue] = React.useState('')

  const deposit = async (e) => {
    e.preventDefault()
    const new_saldo = parseInt(value) + balance;
    const formated_user = { ...user, saldo: new_saldo >= 0 ? new_saldo : 0 };
    try {
      await userService.update(_.omit(formated_user, ['password', 'token']))
      await getUserSaldo()
      setValue('')
      window.swal.fire({
        icon: 'success',
        title: 'Success'
      })
    } catch (e) {
      window.swal.fire({
        icon: 'error',
        title: 'Error'
      })
    }
  }

  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          TYPE IN BELOW HOW MUCH YOU WANT TO DEPOSIT!
        </PageTitle>
        <Balance>
          BALANCE: G$ {price(balance)}
        </Balance>
        <FormContainer onSubmit={deposit}>
          <FormInputPadding>
            <Input value={value} leftIcon leftIconSrc={'las la-money'} onChange={e => setValue(e.target.value)} placeHolder={"Valor..."} autofocus />
            <Separator />
            <CustomButton name='DEPOSIT' />
          </FormInputPadding>
        </FormContainer>
      </PageMiddle>
    </PageContainer>
  )
}

const FormContainer = styled.form`
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
`

const Balance = styled(PageTitle)`
  color: green !important;
`

const FormInputPadding = styled.div`
  padding: 30px 20px;
`

const Input = styled(CustomInput)`
  border: 1px solid black;
  border-radius: 4px;
  color: black;
`

const Separator = styled.div`
margin-top: 30px;
`