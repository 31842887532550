import React, { useState } from 'react'
import styled from 'styled-components'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      adicionarSaldo: true,
      title: 'Unit 1 - What´s your name',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSf5j9vr6x0MmzaiPSuocvA1wARwjVPZdS3C0ugIXP8oJdQgYg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 2 - Where are my keys?',
      url: "https://docs.google.com/forms/d/e/1FAIpQLScvmfsq0CazC2wkpqLzyvdBlxKe3xcz1UfSqZTIkfT0ioRbhQ/viewform"
    },
    {
      adicionarSaldo: true,
      title: 'Unit 3 - Where are you from?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSch9RAeLttkKPjfXtyXfMoqU-mF8SNld29CJsv34CMBQt67Mw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 4 - Is this coat yours?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeIHf2nudJtXXzjWXSh4hYR5MqJGgED0yIVG6TfCl99xfIemQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 5 - What time is it?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfD151VvKP7ljFidlKohQ2vZjY0B6W3lfnCrQOgRfvSrn2Dng/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 6 - I ride my bike to school',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSf4eh7VbS6QJ3Mcih83vx-hZOLFT2D3OVbE_ULs0XfdBhfdMg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 7 - Does it have a view',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSde88u8-IBWRGIZTOsQnzBVhL31trrtJNyZkmYBfDoTYBpUyQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 8 - Where do you work?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfVVMeX7wONakpqMw-Eq8lhONUWoDGC7P5M8TtWLDzCh5ASuw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 9 - I always eat breakfast',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfQX2MROGj0ImN3J1FPk9vx2bqHxYeiK7fAJA4PisiDiTa-kw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 10 - What sports do you lke?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdo1xGWx-IjXFQCGhjws4Hbze_LqKSrpODKaUHi075UpGuDvw/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 11 -  I'm going to have a party?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScepD94YLB5bAz_y6-YMaWSl7fnwUDMWYOchwE_yxAt411WLw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 12 - How do you feel?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScZJDKUzz3sBmwhALVN4dIhcLSQ7oAYmWzH5htgwVgYesiriw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 13 - How do I get there?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdA2Vcw-xEi7JOcp4A3LIJyyzS_x0bmppu_-mXR7anBjZyN_Q/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 14 - I had a good time',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdqinhvCr7yGmU5amzGTzY-SwUx0s0VFwUYEnY4tk-7tEkyDw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 15 - Where were you born?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSffVDoKuEeo49pTIcXGz60ItgDUJ9D4ZsuN2YPkKnMj2zJDPw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 16 - Can I take a message?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScV3n-j6p1tvsBfcV3vcMVc9joB1dOZvSc5pXbAc7XfBAU8GA/viewform'
    },

  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          ONLINE WORKBOOK
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard color={'yellow'}{...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
