import styled from 'styled-components'

export const InputWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: ${(props) => {
    const color = `1px solid white`
    return props.bordered ? color : '0px'
  }};
  border-bottom: ${(props) => {
    const color = `1px solid white`
    return color
  }};
  border-left: ${(props) => {
    const color = `1px solid white`
    return props.bordered ? color : '0px'
  }};
  border-right: ${(props) => {
    const color = `1px solid white`
    return props.bordered ? color : '0px'
  }};
`

export const Input = styled.input `
  flex: 1;
  border: 0px !important;
  color: white;
  `

export const LeftIcon = styled.i `
  font-size: 16px;
  color: white;
`
export const RightIcon = styled.i `
  font-size: 16px;
  color: white;
`