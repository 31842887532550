import React from 'react'
import styled from 'styled-components'
import { CustomButton } from '../'
import ModalImage from "react-modal-image";

export default ({ name, link, }) => {

  function onButtonTap(item) {
    alert(`Se voceê deseja comprar o item ${item}, envie um email para: \n didatico@yesidoxp.com utilizando o título: 
        I want to buy: ${item}\n
        Na descrição, coloque como deseja, se for uma camiseta ou caneca, envie também a foto em boa resolução. \n
        Caso deseje criar uma arte própria para a caneca, envie uma foto no tamanho de 21cm x 9cm em ótima qualidade e iremos fazer para você.
        `)
  }

  return (
    <>
      <ModalImage
        small={link}
        large={link}
        alt="Hello World!"
      />
    </>
  )
}

const CardContainer = styled.div`
  flex: 1;
  background-color: white;
  width: 50%;
  flex-basis: 33%;
  -webkit-box-shadow: 0px 2px 7px 5px rgba(209, 209, 209, 0.88);
  -moz-box-shadow: 0px 2px 7px 5px rgba(209, 209, 209, 0.88);
  box-shadow: 0px 2px 7px 5px rgba(209, 209, 209, 0.88);
  margin: 10px;
`

const TextWrapper = styled.div`
  min-height: 220px;
  min-width: 350px;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-end;
  color: #2c3e50;
  text-transform: uppercase;
  font-size: 14px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  font-weight: bold;
  -webkit-box-shadow: 0px 4px 5px -1px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 4px 5px -1px rgba(148, 148, 148, 1);
  box-shadow: 0px 4px 5px -1px rgba(148, 148, 148, 1);
  @media (max-width: 550px) {
    font-size: 16px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  gap: 10px;
`

const ButtonWrapper = styled(CustomButton)`
  font-size: 12px;
  @media (max-width: 550px) {
    font-size: 13px;
  }
`