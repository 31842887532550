import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import GlobalStyles from '../global-styles'
import { Login, Store, Home, MoneyGiftConfirm, MoneyGift, DepositMoneyGroup, OnlineWorkbook, OnlineWorkbook2, OnlineWorkbook3, OnlineWorkbook4, Teachers, Users, ForgotPassword, CreateUser } from '../pages'
import Toastify from 'toastify-js'
import { UserContext } from '../contexts'
import { getUserSession } from '../adapters'
import { PrivateRoute } from '../components'
import { ThemeProvider } from 'styled-components'
import Swal from 'sweetalert2'
import Helmet from 'helmet'

export default () => {

  React.useEffect(() => {
    window.toast = Toastify;
    window.swal = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }, [])

  const [user, setUser] = React.useState(getUserSession())

  return (
    <UserContext.Provider value={{
      user,
      setUser
    }}>
      <Helmet
        titleAttributes={{ itemprop: "name" }}
        htmlAttributes={{
          itemscope: "",
          itemtype: "http://schema.org/WebPage",
        }}
      >
        <link rel="manifest" href="/manifest.webmanifest.json" />
        <meta name="theme-color" content="#E53012" />
      </Helmet>
      <ThemeProvider theme={{}}>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/money-gift/confirm/:id'>
              <MoneyGiftConfirm />
            </Route>
            <Route path='/forgot-password'>
              <ForgotPassword />
            </Route>
            <PrivateRoute path='/' component={Home} exact />
            <PrivateRoute path='/deposit-money-group' component={DepositMoneyGroup} />
            <PrivateRoute path='/money-gift' component={MoneyGift} />
            <PrivateRoute path='/teacher' component={Teachers} />
            <PrivateRoute path='/store' component={Store} />
            <PrivateRoute path='/online-workbook' component={OnlineWorkbook} />
            <PrivateRoute path='/online-workbook-2' component={OnlineWorkbook2} />
            <PrivateRoute path='/online-workbook-3' component={OnlineWorkbook3} />
            <PrivateRoute path='/online-workbook-4' component={OnlineWorkbook4} />

            <PrivateRoute path='/users' component={Users} />
            <PrivateRoute path='/create-user/:id?' component={CreateUser} />
            <PrivateRoute component={Home} />
          </Switch>
        </Router>
      </ThemeProvider>
    </UserContext.Provider >
  )
}