import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo_fundo_transparente.png'
import { CustomInput, CustomButton, Spinner } from '../components'
import { userService } from '../services'
import { setUserSession } from '../adapters'
import { UserContext } from '../contexts'
import { withRouter } from 'react-router-dom'
export default withRouter((props) => {

  const { setUser } = useContext(UserContext);

  const [state, setState] = useState({
    username: '',
    password: '',
    loading: false
  })

  const login = async (e) => {
    e.preventDefault()
    setState({ ...state, loading: true })
    try {
      const user = await userService.login({ username: state.username.trim().toLocaleLowerCase(), password: state.password.trim().toLocaleLowerCase() })
      setUserSession(user)
      setUser(user)
      props.history.push('/')
    } catch (errors) {
      setState({ ...state, loading: false })
      window.toast({
        text: errors.map(i => `${i}<br/>`),
        close: true,
        duration: 3000,
        stopOnFocus: true,
      }).showToast();
    }
  }

  return (
    <>
      {state.loading && <Spinner />}
      <LoginContainer>
        <LoginBox onSubmit={login}>
          <CustomInput
            onChange={(e) => setState({ ...state, username: e.target.value })}
            leftIcon={true}
            leftIconSrc={'las la-envelope'}
            style={{ backgroundColor: 'transparent' }}
            placeholder={'Username'}
            type={'username'}
            required
          />
          <InputSeparator>
            <CustomInput
              onChange={(e) => setState({ ...state, password: e.target.value })}
              leftIcon={true}
              leftIconSrc={'las la-lock'}
              style={{ backgroundColor: 'transparent' }}
              placeholder={'Senha'}
              type={'password'}
              required
            />
          </InputSeparator>
          <InputSeparator>
            <CustomButton style={{ backgroundColor: '#FF3366', color: 'white' }} name={'LOGIN'} />
          </InputSeparator>
        </LoginBox>
      </LoginContainer>
    </>
  )
})

const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${Logo});
  background-position: center;
  background-size: contain; 
  background-color: black;
  background-repeat: no-repeat, repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoginBox = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: -webkit-linear-gradient(top, rgba(146, 135, 187, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(146, 135, 187, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25), -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin: 50px;
  max-width: 400px;
  height: 100%;
  max-height: 425px;
  padding: 50px;
`

const InputSeparator = styled.div`
  margin: 15px 0px;
`

const TextBottom = styled.div`
color: #ABA8AE;
`

const TextLink = styled.a`
  color: #fff;
  cursor: pointer;
`