import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getUserSession } from '../../adapters'

export default ({ component: Component, ...rest }) => {
  return <Route
    {...rest}
    render={(props) => {
      return getUserSession() != null ? <Component {...props} /> : <Redirect to="/login" />
    }}
  />
}