import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo_fundo_transparente.png'
import { CustomButton, Spinner } from '../components'
import { userService } from '../services'
import { withRouter } from 'react-router-dom'
export default withRouter((props) => {

  const [state, setState] = useState({
    loading: true
  })

  useEffect(() => {
    if (!props.match?.params.id) return props.history.push('/login')
    async function validate() {
      try {
        await userService.moneyGiftValidate(props.match.params.id)
        setState({ loading: false })
      }
      catch (e) {
        props.history.push('/login')
      }
    }

    validate()
  })
  const login = async (e) => {
    props.history.push('/login')
  }

  return (
    <>
      {state.loading && <Spinner />}
      <LoginContainer>
        <LoginBox onSubmit={login}>
          <InputSeparator style={{ margin: '100px 0px' }}>
            {state.isLoading === false &&
              <CustomButton
                style={{ backgroundColor: 'white', color: '#FF3366', padding: '20px 0px' }}
                name={'DEPOSITO RECEBIDO COM SUCESSO!'}
              />
            }
          </InputSeparator>
          <InputSeparator />
          <TextBottom>
            {`Want more help? `}
            <TextLink href='https://www.youtube.com/watch?v=S-60dXomoOM&feature=youtu.be'>
              We have a full guide to how you can use the WebSite, click here!
            </TextLink>
          </TextBottom>
        </LoginBox>
      </LoginContainer>
    </>
  )
})

const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${Logo});
  background-position: center;
  background-size: contain; 
  background-color: black;
  background-repeat: no-repeat, repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoginBox = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: -webkit-linear-gradient(top, rgba(146, 135, 187, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(146, 135, 187, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s, transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25), -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin: 50px;
  max-width: 400px;
  height: 100%;
  max-height: 425px;
  padding: 50px;
`

const InputSeparator = styled.div`
  margin: 15px 0px;
`

const TextBottom = styled.div`
color: #ABA8AE;
`

const TextLink = styled.a`
  color: #fff;
  cursor: pointer;
`