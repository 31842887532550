import React, { useState } from 'react'
import styled from 'styled-components'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      title: 'User Management',
      url: '/users'
    },
    {
      title: 'Money Gift',
      url: '/money-gift'
    },
    {
      title: 'May - Matriz de Atividades',
      url: '/online-workbook'
    },
    {
      title: 'Study Guide - Guia com dicas para seu inglês',
      url: 'https://drive.google.com/open?id=1SEb9p3ixNEO6LIcJH0yrGPMoDdXyUQxM'
    },
    {
      title: 'ESL LAB - Laboratório de Atividades',
      url: 'https://www.esl-lab.com/'
    },
    {
      title: 'TEACHERS ATTENDANCE.',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScEUzRizMcbiSoLMwkkUlasw7mf_tTKTpactf-H1EddKA7UAA/viewform'
    },
    {
      title: 'Timetable',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeIFnQzH1fFEmkQAV1hFWLGncrnbLumVBOHi3Ia5xM95uAA4w/viewform'
    },
    {
      title: 'Teaching Development',
      url: 'https://drive.google.com/drive/folders/0B-17wDQ5xbuAYkx0X1FjT2hsbDg'
    },
    {
      title: 'Deposit to Group',
      url: '/deposit-money-group'
    },
    {
      title: 'Create user',
      url: '/create-user'
    },
    {
      title: 'YES, I DO DRIVE',
      url: 'https://drive.google.com/drive/folders/0B-17wDQ5xbuAb0g1RURZU2pQVUU'
    },
  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          TEACHER'S AREA
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard {...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
