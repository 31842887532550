import axios from '../utils/axios';
import _ from 'lodash'

export const login = ({ username, password }) => {
  return axios.post('user/login', { username, password });
};

export const create = (params) => {
  return axios.post('user', { ...params });
};

export const adicionarSaldo = () => {
  return axios.post('user/adicionarSaldo');
};

export const findOne = (id) => {
  return axios.get('user/' + id);
};

export const update = (user) => {
  const { id } = user
  user = _.omit(user, ['id', 'password'])
  return axios.put(`user/${id}`, { ...user });
};

export const listAll = (user) => {
  return axios.get(`user`);
}

export const findByGroup = (group_id) => {
  return axios.get(`user/group/${group_id}`);
}

export const saldo = () => {
  return axios.get(`user/saldo`);
}

export const comprar = (product_id) => {
  return axios.post(`user/comprar`, { product_id });
}

export const resetPassword = (userId) => {
  return axios.put(`user/resetPassword/${userId}`);
}

export const inactiveUser = (userId) => {
  return axios.put(`user/inactive/${userId}`)
}

export const moneyGiftValidate = (id) => {
  return axios.post(`user/money/${id}/validate`)
}