import React, { useState, useRef, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import * as Styles from './left-side-bar-styles'
import LogoAdmin from '../../assets/logo_fundo_transparente.png'
import { UserContext } from '../../contexts'

const LeftSideBar = () => {

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setActive(false)
  });

  const { user } = useContext(UserContext);

  const icons = [
    { name: 'las la-user', route: '/account', title: `${user.first_name} ${user.last_name}`, role_id: 1 },
    { name: 'las la-sign-out-alt', route: '/login', title: 'LOGOUT', role_id: 1, },
    { name: 'las la-home', route: '/', title: 'HOME', role_id: 1, },
    { name: 'las la-store', route: '/store', title: 'STORE', role_id: 1 },
    { name: 'las la-user', route: '/teacher', title: 'TEACHER AREA', role_id: 2 },
  ]

  const [active, setActive] = useState(false)

  const iconsFiltered = user.role_id == 1 ? icons.filter(i => i.role_id == 1) : icons

  return (
    <Styles.SidebarOverlay active={active}>
      <Styles.SidebarContainer ref={ref}
        active={active}>
        {active && <Styles.SidebarLogoActive src={LogoAdmin} />}
        {!active && <Styles.SidebarMenuIcon className={'las la-bars'} active={active} onClick={() => setActive(!active)} />}
        {iconsFiltered.map((icon, iconKey) => {
          return (
            <NavLink
              key={iconKey}
              exact
              to={icon.route}
              className='icon-container'
              activeClassName='true'
            >
              <Styles.SidebarNormalIconsContainer>
                <Styles.SidebarNormalIcons className={icon.name} />
                {!!active && icon.title}
              </Styles.SidebarNormalIconsContainer>
            </NavLink>
          )
        })}
      </Styles.SidebarContainer>
    </Styles.SidebarOverlay>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default LeftSideBar
