import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CustomSidebar, CustomInput, PageTitle, PageMiddle, PageContainer, TableBody, TableBodyItem, TableContainer, TableHeader, TableHeaderItem, TableWrapper, TableTR } from '../components'
import { userService } from '../services'
import { useHistory } from 'react-router-dom'
import _ from 'lodash';

const status = {
  1: 'ATIVO',
  2: 'INATIVO'
}
export default () => {
  const history = useHistory()
  const [filter, setFilter] = useState('')
  const [users, setUsers] = useState([]);
  const [userFiltered, setUsersFiltered] = useState([]);

  useEffect(() => {
    (async () => {
      const usersList = await userService.listAll();
      setUsers(usersList)
      setUsersFiltered(usersList)
    })()
  }, [])

  useEffect(() => {
      if(filter.length === 0){
        return setUsersFiltered(users)
      }
      const filterLower = filter.toLowerCase()
      const usersF = users.filter(i => i.username.toLowerCase().includes(filterLower) ||  i.grupo.toLowerCase().includes(filterLower) ||  i.first_name.toLowerCase().includes(filterLower))
      setUsersFiltered(usersF)
  }, [filter])

  const deleteUser = async (userId) => {
    await userService.inactiveUser(userId)
    const usersFiltered = [...users].filter(user => user.id !== userId)
    setUsers(usersFiltered)
    setUsersFiltered(usersFiltered)
    await window.swal.fire({
      icon: 'success',
      title: 'Success'
    })
  }

  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle style={{ padding: '30px 30px', paddingLeft: 90 }}>
        <PageTitle style={{ marginBottom: 10, position: 'relative' }}>
          USER MANAGEMENT
          <div style={{ position: 'absolute', width: '100%', height: 10, background: '#f1f2f6' }} />
          <InputContainer>
            <CounterContainer>
              TOTAL: {userFiltered.length}
              </CounterContainer>
            <Input value={filter} onChange={e => { setFilter(e.target.value) }} placeholder={'Search...'} />
          </InputContainer>
        </PageTitle>
        <TableDivContainer>
          <TableContainer>
            <TableHeader>
              <TableTR style={{ backgroundColor: '#E50D4B', opacity: 1 }}>
                <TableHeaderItem>NOME</TableHeaderItem>
                <TableHeaderItem>USER</TableHeaderItem>
                <TableHeaderItem>GRUPO</TableHeaderItem>
                <TableHeaderItem style={{ textAlign: 'center' }}>STATUS</TableHeaderItem>
                <TableHeaderItem style={{ textAlign: 'center' }}>SALDO</TableHeaderItem>
                <TableHeaderItem style={{ textAlign: 'center' }}>EDITAR</TableHeaderItem>
                <TableHeaderItem style={{ textAlign: 'center' }}>INATIVAR</TableHeaderItem>
              </TableTR>
            </TableHeader>
            <TableBody>
              {userFiltered.map(user => {
                return (
                  <TableTR>
                    <TableBodyItem align={'center'}>{user.first_name}</TableBodyItem>
                    <TableBodyItem align={'center'}>{user.username}</TableBodyItem>
                    <TableBodyItem align={'center'}>{user.grupo}</TableBodyItem>
                    <TableBodyItem style={{ textAlign: 'center' }}>{status[user.status]}</TableBodyItem>
                    <TableBodyItem style={{ textAlign: 'center' }}>{user.saldo}</TableBodyItem>
                    <TableBodyItem style={{ textAlign: 'center' }}><i className={'las la-pen cp'} onClick={() => history.push('/create-user/' + user.id)} /></TableBodyItem>
                    <TableBodyItem style={{ textAlign: 'center' }}><i className={'las la-trash cp'} onClick={() => deleteUser(user.id)} /></TableBodyItem>
                  </TableTR>
                )
              })}
            </TableBody>
          </TableContainer>
        </TableDivContainer>
      </PageMiddle>
    </PageContainer>
  )
}

const TableDivContainer = styled.div`
display: block;
margin-top: 35px;
@media(max-width: 1000px){
  width: calc(100vw - 120px);
};
max-height: calc(100vh - 123px);
overflow-x: auto;
-webkit-overflow-scrolling: touch;
-ms-overflow-style: -ms-autohiding-scrollbar;
`

const InputContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  right: 0;
`

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  color: black;
  min-width: fit-content;
`;
const Input = styled(CustomInput)`
  color: black;
  &:nth-child(1) {
    &::placeholder {
      color: black;
      opacity: .5;
    }
}
`