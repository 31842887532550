import React, { useState } from 'react'
import styled from 'styled-components'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      adicionarSaldo: true,
      title: 'Unit 1 - Where are you from?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdB9XD8lYOd9LHL8_d1s4tC3z-XDPmIYVt2k178M9OlE930Ig/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 2 - Where are my keys?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfdeQ8kebkPR7mYQz-zx8fpc2APxqA1PF3VDAHGl_gVojb7DQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 3 - How much are these',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSecdW3YR7_co17Vp3UT4VwkeoQ9AeqshO6guHDRHL-_jsjXIw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 4 - Do you play guitar?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdBbuJWcTF3XDQe_5YnsHFoTWALalA7nJXcaRjoCwqcIVj3jg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 5 - What an interesting family!',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfttKwDhL0VDgZxxSmFc0IS6zPy2tyKTWqnCE5r1FymL_hodg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 6 - How often do you run?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScRfEKpjQBEZXnqv4I3mZEbqZvUUX3r-zfZKnl5nRR1E2vfSw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 7 - We want dancing',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSde88u8-IBWRGIZTOsQnzBVhL31trrtJNyZkmYBfDoTYBpUyQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 8 - How's the neighborghood?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSc6-Rq3pRgGHTlajNgT2MVNkhU636IoEdEfdix9dy15yZiFzA/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 9 - What does she look like?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScwf80drJ3Q6hMqJQDjGybuXqmBCIWKrGkgn-10j40fYyV1pg/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 10 - Have you ever been there",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSe7LorURrBoRuXrs1FBuyGMRapiLFJLzjrPk_aV4ZZRVcY58A/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 11 - It's a really nice city",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSc-T9I_krhGWcT59z6h45pAPBjujlkP9Etr9Ah5-Kyua2rePQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 12 - It's important to get rest",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeWEQIr5ib6vopsqo5yweIqe39eYGyaedYi5nAxmNIBHGKEMg/viewform?usp=share_link'
    },
    {
      adicionarSaldo: true,
      title: "Unit 13 - What would you like?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScbf9gIAPVj6PbvUzPhpfIKszR5hZYTKUQ2MCKUk0IWve1bRw/viewform?usp=share_link'
    },
    {
      adicionarSaldo: true,
      title: "Unit 14 - It's the coldest city!",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScyzHbm5EXSAFYY8FTP8taHU-li7Q1JTTaAv1xZ1d0DZAbKwQ/viewform?usp=share_link'
    },
    {
      adicionarSaldo: true,
      title: "Unit 15 - What are you doing later?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeF7ApIH6ShmrSI9qyFzHB2uvK5lNK_AiR-Fz5r1JAk_qwlJw/viewform?usp=share_link4'
    },
    {
      adicionarSaldo: true,
      title: "Unit 16 - How have you changed?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfTVkr1Xde2D4Os9XAzx6z5Ymp9N0f7xuLNVfaqGNNrmdoblw/viewform?usp=share_link'
    },
  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          Student Book 2 - Step Up!
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard color={'red'} {...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
