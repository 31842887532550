import React, { useState } from 'react'
import styled from 'styled-components'

export const PageMiddle = styled.div`
  flex: 1;
  padding: 30px;
  padding-left: 90px;
`

export const PageTitle = styled.div`
  text-transform: uppercase;
  color: #666 !important;
  font-weight: normal;
  font-size: 16px;  
  margin-bottom: 10px;
  background-color: #f1f2f6;
`

export const CardContainer = styled.div`
  display: flex;
  margin: 0px -10px;
  flex-wrap: wrap;
`

export const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`