import React from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'


const Pagination = ({ activeIndex, total, onChange }) => {
  if (!total || total === 0) return null
  return (
    <DivPaginacao className='paginator-container'>
      <ReactPaginate
        forcePage={activeIndex}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={total}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => {
          return selected !== activeIndex && onChange(selected)
        }}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'itenPaginationActive'}
      />
    </DivPaginacao>
  )
}

Pagination.defaultProps = {
  activeIndex: 0,
  total: 0,
  onChange: () => ''
}

export default Pagination

export const DivPaginacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  color: #fff;
  &.paginator-container{
    a {
      color: #cecece;
      outline: none;
  }
  .text-action {
      cursor: pointer;
      margin: 0px 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
  }
  .pagination {
      display: flex;
      margin-bottom: 0px !important;
      li, .itenPaginationActive {
          padding: 0px;
          height: 26px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          margin-left: 7px;
          margin-right: 7px;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: bold;
      }
      .previous, .next {
        color: black;
        opacity: 0.6;
        margin: 0px 10px;
      }
      .itenPaginationActive {
          a {
              color: black;
              font-weight: 600;
              outline: none;
          }
      }
    }
  }
`
