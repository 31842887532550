import React from 'react'
import * as Styles from './input-styles'

const CustomInput = (props) => {

  const enableInput = (event)=> {
    event.target.readOnly = false
  }

  const handleChange = (event) => {
    props.onChange(event)
  }

  const rightStyle = props.rightIconStyle ? props.rightIconStyle : {}
  return (
    <Styles.InputWrapper {...props}>
      {props.leftIcon && props.leftIconSrc && <Styles.LeftIcon className={props.leftIconSrc} />}
      <Styles.Input {...props} onFocus={enableInput} onChange={handleChange} />
      {props.rightIcon && props.rightIconSrc && <Styles.RightIcon className={props.rightIconSrc} style={rightStyle} />}
      <div>
      </div>
      {/* {props.rightIcon && props.rightIconSrc && <Styles.RightIcon className={props.rightIconSrc} />} */}
    </Styles.InputWrapper>
  )
}

CustomInput.defaultProps = {
  readOnly: true
}

export default CustomInput
