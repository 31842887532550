import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  font-size: 14px;
  font-weight: 600;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 25px;
  justify-content: center;
  width: 100%;
  &:hover{
    cursor: pointer;
    opacity: 0.7
  }
`
