import React from 'react'
import styled from 'styled-components'
import { CustomIcon } from '../'
import { userService } from '../../services'
export default ({ title, url, adicionarSaldo, color }) => {
  const openUrl = async () => {
    if (adicionarSaldo) {
      await userService.adicionarSaldo()
      return window.location.href = url
    }
    return window.location.href = url
  }

  return (
    <CardContainer onClick={() => openUrl()}>
      <IconWrapper color={color}><Icon color={!!color} className='las la-book' /></IconWrapper>
      <TextWrapper>
        {title}
      </TextWrapper>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  flex: 1;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  min-width: 200px;
  max-width: 400px;
  flex-basis: 33%;
  -webkit-box-shadow: 0px 2px 7px 5px rgba(209,209,209,0.88);
  -moz-box-shadow: 0px 2px 7px 5px rgba(209,209,209,0.88);
  box-shadow: 0px 2px 7px 5px rgba(209,209,209,0.88);
  margin: 10px;
`

const TextWrapper = styled.div`
  padding: 10px;
  color: #2c3e50;
  text-transform: uppercase;
  font-size: 14px;
  @media(max-width: 550px){
    font-size: 12px;
  }
`

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color ? color : 'rgba(0, 0,0, 0.4)'};
`

const Icon = styled(CustomIcon)`
  font-size: 30px;
  padding: 10px 30px; 
  color: ${({ color }) => color ? '#bdc3c7' : 'white'};
`