import React from 'react'
import * as Styles from './button-styles'

const Button = (props) => {
  return (
    <Styles.Button {...props} >
      {props.name}
    </Styles.Button>
  )
}

Button.defaultProps = {
  type: 'submit'
}

export default Button
