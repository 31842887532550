import styled from 'styled-components'

export const TableWrapper = styled.div``

export const TableContainer = styled.table``

export const TableHeader = styled.thead``

export const TableTR = styled.tr``

export const TableHeaderItem = styled.th`
  padding: 15px 25px;
`

export const TableBody = styled.tbody``

export const TableBodyItem = styled.td`
  padding: 15px 25px;
`
