import axios from "axios";
import { getUserSession, setUserSession } from '../adapters'

const backUrl = {
  local: 'http://localhost:4000/',
  remote: 'https://yesidoenglishcourse-back.herokuapp.com/'
}
const connect = axios.create({
  baseURL: backUrl.remote,
});

connect.interceptors.request.use(
  function (config) {
    const session = getUserSession();
    if (session == null) return config;
    config.headers.Authorization = `Bearer ${session.token}`;
    return config;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);
connect.interceptors.response.use(
  function (response) {
    console.log(
      `RESPONSE URL: ${response.config.url}  \n RESPONSE_DATA: ${response.data ? JSON.stringify(response.data) : response
      }`
    );
    return response.hasOwnProperty("data") ? response.data : response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      setUserSession(null)
      window.location.href = '/login'
    } else {
      return await Promise.reject(Object.values(error.response.data));
    }
  }
);

export default connect;
