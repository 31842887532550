import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  * {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  .toastify {
    position: absolute;
    right: 10px;
    padding: 25px 20px;
    background-color: rgba(255,255,255, 0.7);
    span{
      margin-left: 10px;
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
  body {
    background-color: #f1f2f6;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    line-height: 36px;
    border-radius: 4px;
    padding: 0px 40px 0px 8px;
    &::placeholder {
      color: white;
      opacity: .5;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      outline: 0;
    }
  }

  button {
    border-radius: 8px;
    font-size: 16px;
    border: none;
    &:hover {
      opacity: 0.9;
    }
  }
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse:collapse
  }
  tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 00.5);
  }
  tr:nth-child(odd) {
    background: #FFF
  }
  table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    text-align: left;
    background-color: #3498db;
    color: white;
  }
  table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: left;
  }
}
`

export default GlobalStyle
