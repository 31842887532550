import React from 'react'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      adicionarSaldo: true,
      title: "Unit 1 - That's my kind of friend!",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSd3-uitgzJMlFTIwZKx5-2SA0IDXni8_S5e9wZV1oGuwALScQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 2 - Working 9 to 5',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSduoJ5Vja0an5DEnCv30ZOELL2vwuiJBePePG4f3yMcJwjenQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 3 - Lend a Hand',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSc9u4oZoFDNzq7N-5anHPBmacrcKhaUM4M80ucpRtVJb4nixg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 4 - What happened?',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeYXbo5ej_30LkBYSy5BsuoDhIwcR5SwIMh06Q90R8PXhC79A/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 5 - Expanding your horizons",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdmqoUNGW8tIf3298hDHC9NAr5hNXz1eHZgOea1KYpWG7sRVw/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 6 - That needs fixing!',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLScw3I81Af-R6YI_OC08q0uTx0rvL9PMI4bpFLLysvlFnEtqwQ/viewform'
    },
    {
      adicionarSaldo: true,
      title: "Unit 7 - What can we do?",
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSfUv8HhC7nDeh6piqi93lNF6CcqQ_ikrHIDX8jknePDnHxgAg/viewform'
    },
    {
      adicionarSaldo: true,
      title: 'Unit 8 - Never stop learning',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSeiA442n-MdWe1Kyah1s7bBtcSDoP0ndOalyxWw7CvzEW7WnQ/viewform'
    },
  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          Student Book 4 - Free Indeed!
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard color={'green'} {...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
