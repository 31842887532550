import React, { useEffect, useState, useMemo } from 'react'
import { productsService, userService } from '../services'
import styled from 'styled-components'
import price from '../utils/price'

import { CustomSidebar, PageTitle, PageMiddle, CustomCardStore, CardContainer, PageContainer } from '../components'
import { PageAlert } from "../components/partials";

export default () => {

  const products = [{ link: '/images/store/1.png', name: 'Catalog of Products' }, { link: '/images/store/2.png', name: 'Prices' }]
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    (async () => {
      await getUserSaldo()
    })()
  }, [])

  const getUserSaldo = async () => {
    const { saldo } = await userService.saldo()
    setBalance(saldo)
  }

  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          Store
        </PageTitle>
        <Balance>
          BALANCE: G$ {price(balance)}
        </Balance>
        <CardContainer>
          {products.map((item, key) => <CustomCardStore {...item} disabled={true} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}

const Balance = styled(PageTitle)`
  color: green !important;
`