import React, { useState } from 'react'
import styled from 'styled-components'

import { CustomSidebar, PageTitle, PageMiddle, CustomCard, CardContainer, PageContainer } from '../components'

export default () => {

  const links = [
    {
      title: 'Student Book - Intro',
      url: '/online-workbook',
      color: 'yellow'
    },
    {
      title: 'STUDENT BOOK 2 - STEP UP!',
      url: '/online-workbook-2',
      color: 'red'
    },
    {
      title: 'STUDENT BOOK 3 - Freedom!',
      url: '/online-workbook-3',
      color: 'blue'
    },
    {
      title: 'STUDENT BOOK 4 - Free Indeed!',
      url: '/online-workbook-4',
      color: 'green'
    },
    {
      adicionarSaldo: true,
      title: 'Podcasts - Aprenda um novo vocabulario',
      url: 'https://drive.google.com/drive/folders/0B-17wDQ5xbuASUcyZ2NGS3B1Vms?resourcekey=0-wwhBV3dWS3ycsJsfLxq2EA'
    },
    {
      adicionarSaldo: true,
      title: 'ESL - Laboratório de Listening',
      url: 'https://www.esl-lab.com/'
    },
    {
      adicionarSaldo: true,
      title: "Let's take five!",
      url: "https://www.cambridgeenglish.org/learning-english/activities-for-learners?rows=12"
    }
  ]
  return (
    <PageContainer>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          DASHBOARD
        </PageTitle>
        <CardContainer>
          {links.map(link => <CustomCard {...link} />)}
        </CardContainer>
      </PageMiddle>
    </PageContainer>
  )
}
