import React from 'react'
import * as Styles from './icon-styles'

const Icon = (props) => {
  return (
    <Styles.IconDefault {...props}/>
  )
}

export default Icon
