import styled from 'styled-components'
import { CustomIcon } from '../'

export const SidebarOverlay = styled.div`
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  width: ${({ active }) => active ? '100vw' : '60px'}
`
export const SidebarContainer = styled.div`
  width: ${({ active }) => active ? '240px' : 'initial'};
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 6px #2C28284B;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  .icon-container{
    text-decoration: none;
    color: black;
    &.true {
      color: black;
      background-color: #f1f2f6;
    }
  }
`

export const SidebarMenuIcon = styled(CustomIcon) `
  font-size: 30px;
  margin-bottom: 20px;
  color: #bdc3c7;
  cursor: pointer;
  width: 100%;
  padding: 0px 15px;
  margin-top: 20px;
`
export const SidebarLogo = styled(SidebarMenuIcon)``

export const SidebarLogoActive = styled.img`
  width: 200px;
  padding: 10px 15px;
`

export const SidebarUserIcon = styled(CustomIcon)`
  height: 30px;
  max-height: 40px;
  min-height: 30px;
  max-width: 30px;
  border-radius: 50%;
  border: 2px solid #34495e;
  cursor: pointer;
  margin: 0px 15px;
  margin-bottom: 10px;
`
export const SidebarNormalIcons = styled(CustomIcon)`
cursor: pointer;
font-size: 20px;
width: 100%;
max-width: 60px;
padding: 0px 20px;
text-align: left;
display: flex;
align-items: center;
`

export const SidebarNormalIconsContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
`
