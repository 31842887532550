import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CustomSidebar, PageTitle, PageMiddle, PageContainer, CustomInput, CustomButton } from '../components'
import { groupsService, userService } from '../services'
import _, { initial } from 'lodash';
import { SimpleSelect } from 'dropdown-select';
import 'dropdown-select/dist/css/dropdown-select.css';
import { useParams } from 'react-router-dom'

const initialUser = {
  username: null,
  first_name: null,
  last_name: null,
  password: null,
  role_id: null,
  group_id: null
}
export default () => {
  const { id } = useParams()
  const [state, setState] = useState({
    groups: [],
    roles: [{
      id: 1, name: 'Student',
    }, {
      id: 2, name: 'Teacher',
    }],
    user: { ...initialUser }
  })

  const getGroups = async () => {
    return await groupsService.listAll()
  }

  useEffect(() => {
    (async () => {
      let new_State = { ...state }
      if (id) {
        const user = await userService.findOne(id)
        new_State.user = user
      }
      new_State.groups = await getGroups()
      setState({ ...new_State })
    })()
  }, [])

  const save = async (e) => {
    let user = { ...state.user }
    if (id) {
      user = _.omit(user.password)
    }
    e.preventDefault()
    if (Object.values(user).includes(null) || Object.values(user).find(item => item.length === 0)) {
      return window.toast({
        text: 'Erro! <br/> Preencha todos os campos.',
        close: true,
        duration: 3000,
        stopOnFocus: true,
      }).showToast();
    }
    try {
      if (id) {
        await userService.update({ ...state.user, id })
      } else {
        await userService.create({ ...state.user })
      }
      await window.swal.fire({
        icon: 'success',
        title: 'Success'
      })
      setState({
        ...state,
        ...initialUser
      })
    } catch (e) {
      window.swal.fire({
        icon: 'error',
        title: 'Error'
      })
    }
  }

  const reset = async () => {
    await userService.resetPassword(id)
    await window.swal.fire({
      icon: 'success',
      title: 'Success'
    })
  }

  return (
    <PageContainer style={{ overflowX: 'hidden' }}>
      <CustomSidebar />
      <PageMiddle>
        <PageTitle>
          USER MANAGEMENT - FORM
        </PageTitle>
        <FormContainer onSubmit={save}>
          <FormInputPadding style={{
            // maxHeight: 'calc(100vh - 95px)',
            // overflowY: 'auto',
            // minHeight: 300,
          }}>
            <PageTitle style={{ backgroundColor: 'white' }}>
              CREATE / EDIT USER
              <ResetContainer onClick={reset}>
                RESET PASSWORD
              </ResetContainer>
            </PageTitle>
            <Separator />
            <label style={{ marginBottom: 5 }}>
              First name
            </label>
            <Input value={state.user.first_name} minLength={2} onChange={e => { setState({ ...state, user: { ...state.user, first_name: e.target.value } }) }} placeholder={'First name...'} />
            <Separator />
            <label style={{ marginBottom: 5 }}>
              Last name
            </label>
            <Input value={state.user.last_name} minLength={2} onChange={e => { setState({ ...state, user: { ...state.user, last_name: e.target.value } }) }} placeholder={'Last name...'} />
            <Separator />
            <label style={{ marginBottom: 5 }}>
              Username
            </label>
            <Input value={state.user.username} minLength={5} onChange={e => { setState({ ...state, user: { ...state.user, username: e.target.value.trim().toLowerCase() } }) }} placeholder={'Username...'} />
            <Separator />
            {!id &&
              <>
                <label style={{ marginBottom: 5 }}>
                  Password
                </label>
                <Input value={state.user.password} minLength={5} onChange={e => { setState({ ...state, user: { ...state.user, password: e.target.value.trim().toLowerCase() } }) }} placeholder={'Password...'} type={'password'} />
                <Separator />
              </>
            }
            <label style={{ marginBottom: 5 }}>
              Group
            </label>
            <Select placeholder={'Select one group...'} options={state.groups} labelKey="name" valueKey="id" value={state.groups.find(i => i.id == state.user.group_id) || ''} onChange={({ id }) => setState({ ...state, user: { ...state.user, group_id: id } })} />
            <Separator />
            <label style={{ marginBottom: 5 }}>
              Role
            </label>
            <Select placeholder={'Select one role...'} options={state.roles} labelKey="name" valueKey="id" value={state.roles.find(i => i.id == state.user.role_id) || ''} onChange={({ id }) => setState({ ...state, user: { ...state.user, role_id: id } })} />
            <div style={{ marginTop: 50, paddingLeft: 50, paddingRight: 50 }}>
              <CustomButton name='SAVE' />
            </div>
            <div style={{ marginBottom: 25 }} />
          </FormInputPadding>
        </FormContainer>
      </PageMiddle>
    </PageContainer>
  )
}

const FormContainer = styled.form`
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
`

const Balance = styled(PageTitle)`
  color: green !important;
`

const FormInputPadding = styled.div`
  padding: 30px 20px;
`

const Select = styled(SimpleSelect)`
&:nth-child(2) {
  &::placeholder {
    color: black !important;
    opacity: .5;
  }
}
`
const Input = styled(CustomInput)`
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: black;
  &:nth-child(1) {
    &::placeholder {
      color: black;
      opacity: .5;
    }
}
`

const Separator = styled.div`
margin-top: 15px;
`

const ResetContainer = styled.div`
margin: 5px 0px;
cursor: pointer;
font-size: 12px;
width: 100%;
display: flex;
text-decoration: underline;
justify-content: flex-end;

`